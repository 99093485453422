import React, { useCallback, useRef, useState } from "react";
import "../../../css/fabricSelectStyles.css";
import { HexColorPicker } from "react-colorful";
import useClickOutside from "../../../utils/useClickOutside";
import debounce from "lodash.debounce";

export const FabricSettings = {
  friendlyId: "friendlyId",
  id: "id",
  hexCode: "hexCode",
  WOF: "wof",
};

/* Constant Summary
Displays the hex color picker for the user to select a
color. Forked from react-colorful
   */
export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ backgroundColor: color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover" ref={popover}>
          <HexColorPicker color={color} onChange={onChange} />
        </div>
      )}
    </div>
  );
};

/*FabricSelect Component Summary
This component allows the user to select their preferred
fabric. Fabrics can be selected with a color and named 
by the user to be referenced in components.
*/
export class FabricSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      friendlyId: props.friendlyId,
      hexCode: props.hexCode,
      id: props.id,
      widthOfFabric: props.widthOfFabric,
    };
    /*Race condition was occurring on user change
    to color or friendlyName, so those functions are
    debounced for 1 second
    */
    this.onChangeSettingDebounced = debounce(
      this.onChangeSettingDebounced,
      1000,
    );
  }

  /* Function Summary
  Updates the color of this fabric selector
   */
  handleFabricColorChange = (color) => {
    console.log(this.state.friendlyId + " set to: " + color);
    this.setState({ hexCode: color });
    this.onChangeSettingDebounced(FabricSettings.hexCode, color);
  };

  /* Function Summary
  Updates the friendlyId of this fabric selector
   */
  handleFabricFriendlyIdChange = (event) => {
    console.log(this.state.friendlyId + " set to: " + event.target.value);
    this.setState({ friendlyId: event.target.value });
    this.onChangeSettingDebounced(
      FabricSettings.friendlyId,
      event.target.value,
    );
  };

  handleWidthOfFabricChange = (event) => {
    console.log(this.state.friendlyId + " set to: " + event.target.value);
    this.setState({ widthOfFabric: event.target.value });
    this.onChangeSettingDebounced(FabricSettings.WOF, event.target.value);
  };

  onChangeSettingDebounced = (input, updatedValue) => {
    this.props.updateFabricSettings(this.state.id, input, updatedValue);
  };

  render() {
    return (
      <div className="fabricSelect" data-testid="fabricSelect">
        <PopoverPicker
          color={this.state.hexCode}
          onChange={this.handleFabricColorChange}
        />
        <div>
          <label className="fabricInputLabel">
            Name:
            <input
              className="fabricIdBlock"
              defaultValue={this.state.friendlyId}
              type="text"
              onChange={this.handleFabricFriendlyIdChange}
            />
          </label>
        </div>
        <div>
          <label className="fabricInputLabel">
            Width of Fabric (in):
            <input
              className="fabricIdBlock"
              defaultValue={this.state.widthOfFabric}
              type="number"
              onChange={this.handleWidthOfFabricChange}
            />
          </label>
        </div>
      </div>
    );
  }
}
