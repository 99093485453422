import React from "react";
import "../../../css/listElementStyles.css";
import { PopoverPicker } from "../../QuiltCalc/FabricSelect/FabricSelect.js";
import { getCompFriendlyName } from "../../BlockComp/BlockComp.js";
import { pxScale, ItemChanges } from "../Piecer.js";

function Menu({ item }) {
  return (
    <div className="menu">
      <span>...</span>
      <ul className="menu-items">
        <li>
          <button onClick={() => item.handleUpdate(item, ItemChanges.Delete)}>
            Delete
          </button>
        </li>
        <li>
          <button
            onClick={() => item.handleUpdate(item, ItemChanges.Duplicate)}
          >
            Duplicate
          </button>
        </li>
      </ul>
    </div>
  );
}

export function ListElement({ item }) {
  function handleColorUpdate(color) {
    let newitem = { ...item };
    newitem.color = color;
    item.handleUpdate(newitem, ItemChanges.Fabric.HexColor);
  }

  switch (item.type) {
    case "comp":
      return (
        <div
          data-testid="listElement comp"
          key={item.id}
          className="listElement comp"
          onClick={() => {
            let newitem = { ...item };
            newitem.selected = !item.selected;
            item.handleUpdate(newitem, ItemChanges.Comp.Selected);
          }}
          style={{ border: item.selected ? "1px solid red" : "" }}
        >
          <Menu item={item} />

          <div className="centeredDivs">
            <div className="menuAdjust">
              {getCompFriendlyName(item.content)}
            </div>
            <div className="menuAdjust">
              <input
                alt="Quilt block component height"
                type="number"
                step="0.25"
                defaultValue={item.height / pxScale}
                onChange={(e) => {
                  let newitem = { ...item };
                  newitem.height = e.target.value * pxScale;
                  item.handleUpdate(newitem, ItemChanges.Comp.Height);
                }}
              />
              x
              <input
                alt="Quilt block component height"
                type="number"
                step="0.25"
                defaultValue={item.width / pxScale}
                onChange={(e) => {
                  let newitem = { ...item };
                  newitem.width = e.target.value * pxScale;
                  item.handleUpdate(newitem, ItemChanges.Comp.Width);
                }}
              />
            </div>
            <div style={{ display: item.selected ? "block" : "none" }}>
              <button
                onClick={() => item.handleUpdate(item, ItemChanges.Comp.Rotate)}
              >
                Rotate
              </button>
              <div className="menuAdjust">colors</div>
            </div>
          </div>
        </div>
      );
    case "fabric":
      return (
        <div key={item.id} className="listElement" data-testid="listElement">
          <Menu item={item} />
          <div className="centeredDivs">
            <div className="menuAdjust">{item.friendlyName}</div>
            <div className="menuAdjust">
              <PopoverPicker color={item.color} onChange={handleColorUpdate} />
            </div>
            <input
              alt={"hex color for " + item.friendlyName}
              type="text"
              value={item.color}
              onChange={(e) => handleColorUpdate(e.target.value)}
            />
          </div>
        </div>
      );
    default:
      return;
  }
}

export default ListElement;
