import React from "react";
import "../../css/homeStyles.css";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import RecirculationUnit from "../RecirculationUnit/RecirculationUnit.js";
import instagramFeed from "../../Assets/instagramFeed.jpg";

export function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact Us | Gibson Threads</title>
        <link rel="canonical" href={"https://gibsonthreads.com/contact"} />
        <meta
          name="description"
          content="Contact the Gibson Threads team for support."
        />
        <meta property="og:title" content="Contact Us | Gibson Threads" />
        <meta
          property="og:description"
          content="Contact the Gibson Threads team for support."
        />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_URL +
            "static/media/fabricSelectEx.4f370e3f180543d4c498.png"
          }
        />
      </Helmet>
      <NavBar displayCenter={false} title="Contact Us" />
      <div className="basicBackground">
        <div>
          <h2>Report an issue, Request a feature</h2>
          <iframe
            src="https://docs.google.com/forms/d/1d0bIDWMo-vt8dL2b2kBcQGz0YYkun8tLlYV0NcuwgAM/viewform?embedded=true"
            width="640"
            height="620"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
        <div>
          <h2>
            Follow us on
            <a
              href="https://www.instagram.com/gibsonthreads/"
              onClick={() =>
                ReactGA.event({
                  category: "Recirclation",
                  action: "link-click",
                  label: "instagram", // optional
                })
              }
            >
              {" "}
              Instagram
            </a>
          </h2>
          <img
            src={instagramFeed}
            className="aboutItemMedia"
            alt="Gibson Threads Instagram Feed"
            href="https://www.instagram.com/gibsonthreads/"
          />
        </div>
        <div className="aboutItemText">
          <h2>Sign up for email updates</h2>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSev1b8Tq2n8yI_Lg7zONrTH8ypUvZlCmQyu--jIMql7LTkIuQ/viewform?embedded=true"
            width="640"
            height="750"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
      </div>
      <RecirculationUnit />
      <Footer />
    </div>
  );
}
