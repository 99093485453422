import { createClient } from "@sanity/client";

const projectId = "kd1eoq9v";
const dataset = "production";

const client = createClient({
  projectId: projectId,
  dataset: dataset,
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: "2023-08-10", // use current date (YYYY-MM-DD) to target the latest API version
});

async function getPosts() {
  const posts = await client.fetch('*[_type == "post"]');
  return posts;
}

function getImageSRC(assetRef) {
  let cleanedRef = assetRef.replace("image-", "");
  cleanedRef = cleanedRef.replace("-svg", ".svg");
  cleanedRef = cleanedRef.replace("-jpg", ".jpg");
  cleanedRef = cleanedRef.replace("-png", ".png");
  return `https://cdn.sanity.io/images/${projectId}/${dataset}/${cleanedRef}`;
}

export { client, getPosts, getImageSRC };
