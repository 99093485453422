import React, { useState } from "react";
import "../../css/piecerStyles.css";
import ListElement from "./ListElement/ListElement.js";
import NavBar from "../NavBar/NavBar.js";
import { PopoverPicker } from "../QuiltCalc/FabricSelect/FabricSelect.js";
import { CompTypes } from "../BlockComp/BlockComp.js";
import { QuiltBlock } from "./ContentSVG/ContentSVG.js";

export const pxScale = 10;

export const ItemChanges = {
  Duplicate: "dup",
  Delete: "del",
  Comp: {
    Rotate: "rot",
    Height: "height",
    Width: "width",
    Colors: "goodLuck",
    Selected: "selected",
  },
  Fabric: {
    FriendlyName: "friendly",
    HexColor: "color",
  },
};

export function Piecer() {
  const [comps, setComps] = useState([
    {
      id: 0,
      content: CompTypes.FlyingGeese,
      x: 0,
      y: 0,
      height: 50,
      width: 100,
      type: "comp",
      rotate: 0,
    },
    {
      id: 1,
      content: CompTypes.Snowball,
      x: 150,
      y: 150,
      height: 50,
      width: 50,
      type: "comp",
      rotate: 0,
    },
    {
      id: 2,
      content: CompTypes.HST,
      x: 100,
      y: 100,
      height: 50,
      width: 50,
      type: "comp",
      rotate: 0,
    },
  ]);

  const [fabrics, setFabrics] = useState([
    {
      id: 0,
      color: "#05878a",
      friendlyName: "1",
      type: "fabric",
    },
    {
      id: 1,
      color: "#67074e",
      friendlyName: "2",
      type: "fabric",
    },
    {
      id: 2,
      color: "#dd9933",
      friendlyName: "3",
      type: "fabric",
    },
  ]);

  const [settings, setSettings] = useState({
    compHeight: 50,
    compWidth: 50,
    blockHeight: 200,
    blockWidth: 200,
    compType: CompTypes.HST,
    columns: 3,
    rows: 4,
    fabricName: fabrics.length,
  });

  const [displayList, setDisplayList] = useState("comps");

  const [selectedElement] = useState({
    comp: "",
    fabric: "",
  });

  const getExport = () => {
    //get quilt pattern repeat info
    let props = {
      key: "",
      blockWidth: settings.blockWidth,
      blockHeight: settings.blockHeight,
      comps: comps,
    };
    let quiltRow = (
      <div style={{ display: "flex" }}>
        {[...Array(settings.columns)].map((e, i) => {
          return <QuiltBlock key={"c" + i} props={props} />;
        })}
      </div>
    );

    let quilt = (
      <div
        className="quiltDesign"
        height={settings.rows * settings.blockHeight + "px"}
        width={settings.columns * settings.blockWidth + "px"}
      >
        {[...Array(settings.rows)].map(() => quiltRow)}
      </div>
    );
    //create display, return
    return quilt;
  };

  const handleAddItem = () => {
    if (displayList === "comps") {
      setComps([
        ...comps,
        {
          id: comps.length,
          content: settings.compType,
          x: 0,
          y: 0,
          height: settings.compHeight,
          width: settings.compWidth,
          type: "comp",
        },
      ]);
    } else {
      setFabrics([
        ...fabrics,
        {
          id: fabrics.length,
          color: "#777777",
          friendlyName: fabrics.length,
          type: "fabric",
        },
      ]);
    }
  };

  function handleItemChange(item, change) {
    let newItem = { ...item };
    let newComps = [...comps];
    let newFabrics = [...fabrics];
    switch (change) {
      case ItemChanges.Delete:
        if (item.type === "comp") {
          setComps(comps.filter((c) => c.id !== item.id));
        } else if (item.type === "fabric") {
          setFabrics(fabrics.filter((f) => f.id !== item.id));
        }
        break;
      case ItemChanges.Duplicate:
        if (item.type === "comp") {
          let newItem = { ...item };
          newItem.id = comps.length + 1;
          setComps([...comps, newItem]);
        } else if (item.type === "fabric") {
          let newItem = { ...item };
          newItem.id = fabrics.length;
          newItem.friendlyName = fabrics.length + 1;
          setFabrics([...fabrics, newItem]);
        }
        break;
      case ItemChanges.Comp.Rotate:
        newItem.rotate = newItem.rotate + 90;
        newComps[newItem.id] = newItem;
        setComps(newComps);
        break;
      //all other cases, update the item in the list
      default:
        if (item.type === "comp") {
          newComps[item.id] = newItem;
          setComps(newComps);
        } else if (item.type === "fabric") {
          newFabrics[item.id] = newItem;
          setFabrics(newFabrics);
        }
        break;
    }
  }

  /* TODO: Determine if needed
  const handleDragEnd = (event, info, itemID) => {
    let updatedComps = comps.map((i) => {
      let updatedComp = i;
      if (i.id === itemID) {
        let newX = i.x + info.offset.x;
        if (newX < 0) {
          newX = 0;
        }
        if (newX > settings.blockWidth - i.width) {
          newX = settings.blockWidth - i.width;
        }
        let newY = i.y + info.offset.y;
        if (newY < 0) {
          newY = 0;
        }
        if (newY > settings.blockHeight - i.height) {
          newY = settings.blockHeight - i.height;
        }
        updatedComp.x = newX;
        updatedComp.y = newY;
        return updatedComp;
      } else {
        return i;
      }
    });
    setComps(updatedComps);
  };
  */

  return (
    <div>
      <NavBar displayCenter={false} title="Piecer" />
      <div className="grid">
        <div className="grid-item leftColumn">
          <div className="compListTabs">
            <div
              className="compListTabs-right"
              onClick={() => setDisplayList("fabrics")}
            >
              Fabrics
            </div>
            <div
              className="compListTabs-left"
              onClick={() => setDisplayList("comps")}
            >
              Components
            </div>
          </div>
          {displayList === "comps" ? (
            <>
              <div className="compList">
                {comps.map((item) => {
                  return (
                    <ListElement
                      key={item.id}
                      item={{
                        ...item,
                        selected: selectedElement.comp === item.id,
                        handleUpdate: handleItemChange,
                      }}
                    />
                  );
                })}
              </div>
              <div className="addItemSettings">
                <label>
                  Height
                  <input
                    type="number"
                    defaultValue={settings.compHeight / pxScale}
                    step="0.01"
                    onChange={(e) =>
                      setSettings((settings) => ({
                        ...settings,
                        compHeight: e.target.value * pxScale,
                      }))
                    }
                  />
                </label>
                <label>
                  Width
                  <input
                    type="number"
                    step="0.01"
                    defaultValue={settings.compWidth / pxScale}
                    onChange={(e) =>
                      setSettings((settings) => ({
                        ...settings,
                        compWidth: e.target.value * pxScale,
                      }))
                    }
                  />
                </label>
                <select
                  onChange={(e) =>
                    setSettings((settings) => ({
                      ...settings,
                      compType: e.target.value,
                    }))
                  }
                >
                  <option default value={CompTypes.HST}>
                    HST
                  </option>
                  <option default value={CompTypes.Rect}>
                    Rectangle
                  </option>
                  <option default value={CompTypes.FlyingGeese}>
                    Flying Geese
                  </option>
                  <option default value={CompTypes.Snowball}>
                    Snowball
                  </option>
                </select>
                <div className="submitButtonContainer">
                  <button
                    className="submitButton"
                    onClick={() => handleAddItem()}
                  >
                    Add component
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="compList">
                {fabrics.map((item) => {
                  return (
                    <ListElement
                      key={item.id}
                      item={{ ...item, handleUpdate: handleItemChange }}
                    />
                  );
                })}
              </div>
              <div className="addItemSettings">
                <label>
                  Name
                  <input
                    type="text"
                    defaultValue={fabrics.length}
                    onChange={(e) =>
                      setSettings((settings) => ({
                        ...settings,
                        fabricName: e.target.value,
                      }))
                    }
                  />
                </label>
                <PopoverPicker color={"#065422"} />
                <div className="submitButtonContainer">
                  <button
                    className="submitButton"
                    onClick={() => handleAddItem()}
                  >
                    Add fabric
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="grid-item midColumn">
          <div className="addItemSettings">
            <label>
              Block Height
              <input
                type="number"
                defaultValue={settings.blockHeight / pxScale}
                step="0.01"
                onChange={(e) =>
                  setSettings((settings) => ({
                    ...settings,
                    blockHeight: e.target.value * pxScale,
                  }))
                }
              />
            </label>
            <label>
              Block Width
              <input
                type="number"
                defaultValue={settings.blockWidth / pxScale}
                step="0.01"
                onChange={(e) =>
                  setSettings((settings) => ({
                    ...settings,
                    blockWidth: e.target.value * pxScale,
                  }))
                }
              />
            </label>
          </div>
        </div>
        <div className="grid-item rightColumn">
          <div className="addItemSettings">
            <label>
              Columns
              <input
                type="number"
                defaultValue={settings.columns}
                step="1"
                onChange={(e) =>
                  setSettings((settings) => ({
                    ...settings,
                    columns: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Rows
              <input
                type="number"
                defaultValue={settings.rows}
                step="1"
                onChange={(e) =>
                  setSettings((settings) => ({
                    ...settings,
                    rows: e.target.value,
                  }))
                }
              />
            </label>
          </div>
          <div>{getExport()}</div>
          <div className="submitButtonContainer">
            <button className="submitButton">
              Get Fabric Calculation {">>"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

/*<motion.div
            className="quiltBlock"
            style={{
              width: settings.blockWidth + "px",
              height: settings.blockHeight + "px",
              margin: "auto"
            }}
            ref={constraintsRef}
            layout
            layoutRoot
          >
            {comps.map((item) => (
              <motion.div
                key={item.id}
                onClick={() =>
                  setselectedElement({ ...selectedElement, comp: item.id })
                }
                drag
                dragMomentum={false}
                dragTransition={{ bounceStiffness: 100, bounceDamping: 20 }}
                dragElastic={0}
                whileTap={{ cursor: "grabbing" }}
                dragConstraints={constraintsRef}
                onDragEnd={(event, info) => handleDragEnd(event, info, item.id)}
                style={{
                  x: item.x,
                  y: item.y,
                  border:
                    selectedElement.comp === item.id ? "1px solid red" : "",
                  height: item.height + "px",
                  width: item.width + "px",
                  cursor: "grab",
                  position: "absolute",
                  rotate: item.rotate
                }}
                layout
              >
                <ContentSVG item={item} />
              </motion.div>
            ))}
          </motion.div>*/

export default Piecer;
