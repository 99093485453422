import React from "react";
import "../../css/blogPostStyles.css";
import "../../css/homeStyles.css";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { MediaTextGrid } from "../About/About.js";
import { Helmet } from "react-helmet";

export function ExampleQuilt(props) {
  let quiltCalcLink = props.quiltCalcLink;

  let title = "Example Quilt:";
  title = title.concat(props.exampleVersion);
  document.title = title;

  return (
    <div>
      <Helmet>
        <title>{title} | Gibson Threads</title>
        <link rel="canonical" href={"https://gibsonthreads.com" + props.slug} />
        <meta
          name="description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta property="og:image" content={props.quiltExImageURL} />
      </Helmet>
      {console.log(props)}
      <NavBar displayCenter={false} title={title} />
      <div className="blogPostPage basicBackground">
        <img
          className="exampleFeaturedMedia"
          src={props.finishedQuilt}
          alt="Beginner Quilt Example Display"
        />
        <MediaTextGrid
          aboutText={
            <div>
              <p>
                In this example, we will be reviewing how to input a basic quilt
                design into the
                <Link
                  to="/quiltcalc"
                  onClick={() =>
                    ReactGA.event({
                      category: "Recirculation",
                      action: "link-click",
                      label: "quiltcalc", // optional
                    })
                  }
                >
                  <span> Quilt Fabric Yardage Calculator.</span>
                </Link>
              </p>
              <p>
                From this finished quilt, we can see a repeating block, shown
                here.
              </p>
            </div>
          }
          imgSRC={props.finishedBlock}
          imgALT="Example of a quilt block."
        />
        <div className="aboutItemText">
          {props.instructions.map((i) => {
            return <p key={i}>{i}</p>;
          })}
        </div>
        <div className="aboutItemText">
          Click to see this in the
          <Link
            to={quiltCalcLink}
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "link-click",
                label: "quiltcalc", // optional
              })
            }
          >
            {" "}
            quilt fabric yardage calculator, including written and visual cut
            recommendations
          </Link>
        </div>
        <div className="aboutItemText">
          <p>
            If you found an issue or have an idea,{" "}
            <Link
              to="https://forms.gle/Jx1SfctEhin9F4TH9"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "link-click",
                  label: "issue-report-link", // optional
                })
              }
            >
              please share to this form
            </Link>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
