import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client, getImageSRC } from "../../../sanity/sanityClientES.js";
import NavBar from "../../NavBar/NavBar.js";
import { Footer } from "../../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import "../../../css/homeStyles.css";

export const cleanedDate = (dirtyDate) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let cleaned = new Date(dirtyDate);
  return (
    months[cleaned.getMonth()] +
    " " +
    cleaned.getDate() +
    ", " +
    cleaned.getFullYear()
  );
};

export const BlogPostList = (display, query) => {
  const [posts, setPosts] = useState([]);
  let title, description;

  if (display === "tagList") {
    useEffect(() => {
      client
        .fetch(`*[tags match "${query}"]`)
        .then((data) => setPosts(data))
        .catch((error) => console.error(error));
    }, []);
    title = "Tags";
    description = `Posts tagged ${query}.`;
  } else {
    useEffect(() => {
      client
        .fetch('*[_type == "post"] | order(date desc)')
        .then((data) => setPosts(data))
        .catch((error) => console.error(error));
    }, []);
    title = "Blog";
    description =
      "Blog posts on calculating fabric needs, project ideas and more.";
  }

  return (
    <div>
      <Helmet>
        <title>Blog | Gibson Threads</title>
        <link rel="canonical" href={"https://gibsonthreads.com/blog"} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title + " | Gibson Threads"} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_URL +
            "static/media/fabricSelectEx.4f370e3f180543d4c498.png"
          }
        />
      </Helmet>
      <NavBar displayCenter={false} title={title} />
      <div className="blogPostListing basicBackground">
        {posts.map((post) => (
          <div key={post._id}>
            <Link
              to={`/blog/${post.slug.current}`}
              className="post-listing"
              onClick={() =>
                ReactGA.event({
                  category: "Recirclation",
                  action: "blog-list-click",
                  label: post.slug.current, // optional
                })
              }
            >
              <img
                src={getImageSRC(post.featuredImage.asset._ref)}
                alt={post.title}
              />
              <div className="text-content">
                {" "}
                {/* This div wraps the text content */}
                <h2>{post.title}</h2>
                <p className="excerpt">{post.excerpt}</p>
                <p className="date">{cleanedDate(post.date)}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default BlogPostList;
