import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 20,
    fontFamily: "Helvetica",
  },
  section: {
    fontSize: "26px",
    fontWeight: 500,
    textAlign: "center",
    color: "#26282c",
    margin: 30,
  },
  imgDisplay: {
    maxHeight: 400,
    objectFit: "contain",
  },
  colorText: {
    fontSize: "10px",
    fontWeight: 200,
    textAlign: "center",
    color: "#26282c",
    margin: 8,
  },
  colorSwatch: {
    width: 50,
    height: 50,
    margin: "auto",
  },
  watermarkLogo: {
    fontSize: "20px",
    fontWeight: 500,
    textAlign: "center",
    color: "#386123",
    margin: 10,
    opacity: 50,
  },
  watermarkLink: {
    fontSize: "10px",
    fontWeight: 200,
    textAlign: "center",
    color: "#6082b6",
    opacity: 50,
    textDecoration: "none",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    float: "bottom",
  },
});

// Create Document Component
export const PDFDocument = ({ paletteColors, imageSRC }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={imageSRC} style={styles.imgDisplay}></Image>
      </View>
      <View style={styles.grid}>
        {paletteColors.map((c) => {
          return (
            <View key={c} style={styles.colorText}>
              <View style={[styles.colorSwatch, { backgroundColor: c }]}></View>
              <Text>{c}</Text>
            </View>
          );
        })}
      </View>
      <View style={styles.footer}>
        <Text style={styles.watermarkLogo}>
          Gibson Threads Palette Generator
        </Text>
        <Link
          style={styles.watermarkLink}
          src="https://www.gibsonthreads.com/color-palette-creator"
        >
          Create your own palette
        </Link>
      </View>
    </Page>
  </Document>
);
