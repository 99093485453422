import React from "react";
import { CompTypes } from "../../BlockComp/BlockComp.js";

export function ContentSVG({ item }) {
  let extPoints =
    "0,0 " +
    item.width.toString() +
    ",0 " +
    item.width.toString() +
    "," +
    item.height.toString() +
    " 0," +
    item.height.toString();

  let transformObject = {};
  if (item.rotateBool) {
    transformObject = { transform: "rotate(" + item.rotate + "deg)" };
  }
  switch (item.content) {
    case CompTypes.Rect:
      return (
        <svg
          data-testid="contentSVG"
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          style={transformObject}
        >
          <polygon points={extPoints} className="contentSVG" />
        </svg>
      );
    case CompTypes.HST: {
      let topTriPoints =
        "0,0 0," +
        item.height.toString() +
        " " +
        item.width.toString() +
        "," +
        item.height.toString();
      let bottomTriPoints =
        "0,0 " +
        item.width.toString() +
        ",0 " +
        item.width.toString() +
        "," +
        item.height.toString();
      return (
        <svg
          data-testid="contentSVG"
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          style={transformObject}
        >
          <polygon points={topTriPoints} className="contentSVG" />
          <polygon points={bottomTriPoints} className="contentSVG" />
        </svg>
      );
    }
    case CompTypes.FlyingGeese: {
      let leftTriPoints =
        "0,0 " +
        (item.width / 2).toString() +
        ",0 " +
        "0," +
        item.height.toString();
      let midTriPoints =
        (item.width / 2).toString() +
        ",0 " +
        item.width.toString() +
        "," +
        item.height.toString() +
        " 0," +
        item.height.toString();
      let rightTriPoints =
        (item.width / 2).toString() +
        ",0 " +
        item.width.toString() +
        "," +
        item.height.toString() +
        " " +
        item.width.toString() +
        ",0";
      return (
        <svg
          data-testid="contentSVG"
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          style={transformObject}
        >
          <polygon points={leftTriPoints} className="contentSVG" />
          <polygon points={midTriPoints} className="contentSVG" />
          <polygon points={rightTriPoints} className="contentSVG" />
        </svg>
      );
    }
    case CompTypes.Snowball: {
      let cornerPoints =
        (item.width * 0.75).toString() +
        ",0 " +
        item.width.toString() +
        "," +
        (item.height * 0.25).toString() +
        " " +
        item.width.toString() +
        ",0";
      return (
        <svg
          data-testid="contentSVG"
          x={item.x}
          y={item.y}
          width={item.width}
          height={item.height}
          style={transformObject}
        >
          <polygon points={extPoints} className="contentSVG" />
          <polygon points={cornerPoints} className="contentSVG" />
        </svg>
      );
    }
    default:
      return <div></div>;
  }
}

export const QuiltBlock = ({ props }) => {
  let viewbox = "0 0 " + props.blockWidth + " " + props.blockHeight;
  return (
    <svg
      data-testid="quiltBlock"
      className="quiltBlock"
      style={{
        width: "auto",
        height: "auto",
      }}
      preserveAspectRatio="xMidYMid none"
      viewBox={viewbox}
    >
      {props.comps.map((i) => {
        return <ContentSVG key={i.id} item={{ ...i, rotateBool: true }} />;
      })}
    </svg>
  );
};

export default ContentSVG;
