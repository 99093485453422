import React from "react";
import { createRoot } from "react-dom/client";
import { BlockForm } from "./Components/QuiltCalc/QuiltCalc.js";
import { Home } from "./Components/Home/Home.js";
import { About } from "./Components/About/About.js";
import { Contact } from "./Components/Contact/Contact.js";
import { AdelineHelper } from "./Components/AdelineHelper/AdelineHelper.js";
import { Piecer } from "./Components/Piecer/Piecer.js";
import { ExampleQuilt } from "./Components/ExampleQuilt/ExampleQuilt.js";
import { LittleCalc } from "./Components/LittleCalcs/LittleCalcs.js";
import BlogPost from "./Components/BlogPost/BlogPost.js";
import { BlogPostList } from "./Components/BlogPost/BlogPostList/BlogPostList.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ColorPaletteCreator from "./Components/ColorPaletteCreator/ColorPaletteCreator.js";

import beginnerEx from "./Assets/QuiltExample_Beginner2.svg";
import beginnerBlockEx from "./Assets/QuiltExample_BeginnerBlock2.svg";
import advancedEx from "./Assets/QuiltExample_Advanced.svg";
import advancedBlockEx from "./Assets/QuiltExample_AdvancedBlockAnnotated.svg";
import exampleData from "./Assets/exampleAssets.json";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-KT1KNBCL3K";
ReactGA.initialize(TRACKING_ID);

export const Event = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/piecer" element={<Piecer />} />
      <Route path="/color-palette-creator" element={<ColorPaletteCreator />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/blog"
        element={<BlogPostList display="fullList" query="" />}
      />
      <Route exact path="/blog/:slug" element={<BlogPost />} />
      <Route
        exact
        path="/tag/:tags"
        element={<BlogPostList display="fullList" query="" />}
      />
      <Route
        path="/quilt-binding-calculator"
        element={<LittleCalc type="binding" />}
      />
      <Route
        path="/quilt-backing-calculator"
        element={<LittleCalc type="backing" />}
      />
      <Route
        path="/examples/beginner"
        element={
          <ExampleQuilt
            exampleVersion={exampleData.beginner.titleAppend}
            slug={exampleData.beginner.slug}
            finishedQuilt={beginnerEx}
            finishedBlock={beginnerBlockEx}
            quiltExImageURL={exampleData.beginner.quiltExImageURL}
            instructions={exampleData.beginner.instructions}
            quiltCalcLink={exampleData.beginner.quiltCalcLinkPath}
          />
        }
      />
      <Route
        path="/quiltcalc/beginnerexample"
        element={
          <BlockForm
            comps={exampleData.beginner.comps}
            fabricObjects={exampleData.beginner.fabricObjects}
            displayFabricEst={false}
            displayFabricCuts={false}
            blockRepeat={9}
          />
        }
      />
      <Route
        path="/examples/advanced"
        element={
          <ExampleQuilt
            exampleVersion={exampleData.advanced.titleAppend}
            finishedQuilt={advancedEx}
            slug={exampleData.advanced.slug}
            finishedBlock={advancedBlockEx}
            quiltExImageURL={exampleData.advanced.quiltExImageURL}
            instructions={exampleData.advanced.instructions}
            quiltCalcLink={exampleData.advanced.quiltCalcLinkPath}
          />
        }
      />
      <Route
        path="/quiltcalc/advancedexample"
        element={
          <BlockForm
            comps={exampleData.advanced.comps}
            fabricObjects={exampleData.advanced.fabricObjects}
            displayFabricEst={false}
            displayFabricCuts={false}
            blockRepeat={9}
          />
        }
      />
      <Route
        path="/quiltcalc"
        element={
          <BlockForm
            comps={[]}
            fabricObjects={[]}
            displayFabricEst={false}
            displayFabricCuts={false}
            blockRepeat={1}
          />
        }
      />
      <Route path="/adeline-quilt-planning-tools" element={<AdelineHelper />} />
    </Routes>
  </Router>,
);
