import React from "react";
import "../../css/homeStyles.css";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import RecirculationUnit from "../RecirculationUnit/RecirculationUnit.js";
import fabricSelectEx from "../../Assets/fabricSelectEx.png";
import compSelectEx from "../../Assets/compSelectEx.png";
import cutRecsEx from "../../Assets/cutRecsEx.png";

export function MediaTextGrid(props) {
  return (
    <div className="aboutItemGrid">
      <div className="aboutItemText">{props.aboutText}</div>
      <div>
        <img className="aboutItemMedia" src={props.imgSRC} alt={props.imgALT} />
      </div>
    </div>
  );
}

export function About() {
  return (
    <div>
      <Helmet>
        <title>
          Fabric Calculator About and How To&apos;s | Gibson Threads
        </title>
        <link rel="canonical" href={"https://gibsonthreads.com/about"} />
        <meta
          name="description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta
          property="og:title"
          content="Fabric Calculator About and How To's | Gibson Threads"
        />
        <meta
          property="og:description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_URL +
            "static/media/fabricSelectEx.4f370e3f180543d4c498.png"
          }
        />
      </Helmet>
      <NavBar displayCenter={false} title="About and How To's" />
      <div className="blogPostPage basicBackground">
        <div className="description">
          <p>
            Thanks for visiting the Gibson Threads Quilt Fabric Calculator. This
            calculator was created to help you estimate the fabric needed for
            your quilt pattern idea.
          </p>{" "}
          <p>Here are instructions on how to use this calculator.</p>
        </div>
        <MediaTextGrid
          aboutText={
            <div>
              <p>
                First, set the number of times you expect this quilt block to
                repeat in the quilt
              </p>
              <p>
                Add the fabrics you plan to use, setting the color, the WOF
                (width of fabric) and a name to reference in the estimates later
                on
              </p>
            </div>
          }
          imgSRC={fabricSelectEx}
          imgALT="Example of fabric selections for the quilt fabric yardage calculator"
        />
        <MediaTextGrid
          aboutText={
            <div>
              <p>
                Add the quilt &quot;components&quot; of each quilt block,
                setting the size (unfinished, inches), repeats within the quilt
                block and the fabrics needed
              </p>
              <p>
                Components are elements of a quilt block, like flying geese,
                HST&apos;s and others. The first iteration of this app only
                references basic components. Feel free to suggest others.
              </p>
            </div>
          }
          imgSRC={compSelectEx}
          imgALT="Example of quilt block component selections for the quilt fabric yardage calculator. Selected as Flying geese block."
        />
        <MediaTextGrid
          aboutText={
            <div>
              <p>
                Finally, click the &quot;Calculate Fabric Needed&quot; to get
                the estimate based on each fabric
              </p>
              <p>
                Components are elements of a quilt block, like flying geese,
                HST&apos;s and others. The first iteration of this app only
                references basic components. Feel free to suggest others.
              </p>
            </div>
          }
          imgSRC={cutRecsEx}
          imgALT="Example of quilt fabric yardage estimates calculated from earlier selections"
        />
        <div className="aboutItemText">
          <p>
            If you found an issue or have an idea,{" "}
            <Link
              to="https://forms.gle/Jx1SfctEhin9F4TH9"
              onClick={() =>
                ReactGA.event({
                  category: "About",
                  action: "faq-click",
                  label: "issue-report-link", // optional
                })
              }
            >
              please share to this form.
            </Link>
          </p>
        </div>
      </div>
      <RecirculationUnit />
      <Footer />
    </div>
  );
}
