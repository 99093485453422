import React from "react";
import "../../css/styles.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import logo from "../../Assets/GibsonThreadLargeLogo.svg";
import favicon from "../../Assets/GibsonThreadsFavicon.svg";

export default function NavBar(props) {
  let classInfo = props.displayCenter
    ? "navbar-container center"
    : "navbar-container left";
  return (
    <div className={classInfo} data-testid={classInfo}>
      <Link
        className="navbar-logo-link"
        to="/"
        onClick={() =>
          ReactGA.event({
            category: "Recirculation",
            action: "navigation",
            label: "home",
          })
        }
      >
        <img
          className="navbar-logo"
          src={props.displayCenter ? logo : favicon}
          alt="Gibson Threads Logo"
        />
      </Link>

      {props.displayCenter ? <></> : <h1>{props.title}</h1>}
    </div>
  );
}

export function SubNav() {
  return (
    <div className="navbar-container left">
      <Link
        className="navbar-link"
        to="/about"
        onClick={() =>
          ReactGA.event({
            category: "Recirculation",
            action: "navigation",
            label: "about",
          })
        }
      >
        About
      </Link>
      <Link
        className="navbar-link"
        to="/quilt-binding-calculator"
        onClick={() =>
          ReactGA.event({
            category: "Recirculation",
            action: "navigation",
            label: "quilt-binding-calculator",
          })
        }
      >
        Binding Calculator
      </Link>
      <Link
        className="navbar-link"
        to="/quilt-backing-calculator"
        onClick={() =>
          ReactGA.event({
            category: "Recirculation",
            action: "navigation",
            label: "quilt-binding-calculator",
          })
        }
      >
        Backing Calculator
      </Link>
      <Link
        className="navbar-link"
        to="/contact"
        onClick={() =>
          ReactGA.event({
            category: "Recirculation",
            action: "navigation",
            label: "contact",
          })
        }
      >
        Contact Us
      </Link>
    </div>
  );
}
