import React from "react";
import "../../css/homeStyles.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import beginnerQuiltEx from "../../Assets/QuiltExample_Beginner2.svg";
import advancedQuiltEx from "../../Assets/QuiltExample_Advanced.svg";

export default function RecirculationUnit() {
  return (
    <div className="homeGrid">
      <div className="homeGrid-full-line-item">
        <h2>See Example Uses of the Quilt Piecing Calculator</h2>
      </div>
      <div className="homeGrid-item">
        <Link
          to="/examples/beginner"
          onClick={() =>
            ReactGA.event({
              category: "Recirculation",
              action: "exampleRecirc",
              label: "beginner",
            })
          }
        >
          <h2>Beginner Quilt Example</h2>
          <img
            className="aboutItemMedia"
            src={beginnerQuiltEx}
            alt="Beginner Quilt Example Display"
          />
        </Link>
      </div>
      <div className="homeGrid-item">
        <Link
          to="/examples/advanced"
          onClick={() =>
            ReactGA.event({
              category: "Recirculation",
              action: "example-recirc",
              label: "advanced",
            })
          }
        >
          <h2>Advanced Quilt Example</h2>
          <img
            className="aboutItemMedia"
            src={advancedQuiltEx}
            alt="Advanced Quilt Example Display"
          />
        </Link>
      </div>
    </div>
  );
}
