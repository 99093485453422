import React from "react";
import "../../css/styles.css";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { BottomNavigation } from "@mui/material";

export class Footer extends React.Component {
  render() {
    return (
      <BottomNavigation className="footer">
        <div className="footer-item">
          <a
            href="https://www.instagram.com/gibsonThreads"
            onClick={() =>
              ReactGA.event({
                category: "Recirclation",
                action: "footer-click",
                label: "instagram", // optional
              })
            }
          >
            Instagram
          </a>
        </div>
        <div className="footer-item">
          <a
            href="https://forms.gle/g3CEUszWq11qCExw6"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "footer-click",
                label: "email-updates", // optional
              })
            }
          >
            Email Updates
          </a>
        </div>
        <div className="footer-item">
          <Link
            to="/contact"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "footer-click",
                label: "contact", // optional
              })
            }
          >
            Contact Us
          </Link>
        </div>
      </BottomNavigation>
    );
  }
}
