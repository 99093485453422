import React, { useState } from "react";
import "../../css/styles.css";
import "../../css/quiltCalcStyles.css";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import {
  printFabricEstHelperImperial,
  printFabricEstHelperMetric,
} from "../../utils/calcUtils.js";

export function LittleCalc(props) {
  let pageTitle, pageDescription, imgSource, content, slug;

  //TODO - Handle helmet based on props
  if (props.type === "binding") {
    pageTitle = "Quilt Binding Calculator";
    pageDescription =
      "Calculate the fabric needed for your quilt binding in yards or meters.";
    imgSource = "";
    content = <BindingCalc />;
    slug = "/quilt-binding-calculator";
  } else {
    pageTitle = "Quilt Backing Calculator";
    pageDescription =
      "Calculate the fabric needed for your quilt backing in yards or meters.";
    imgSource = "";
    content = <BackingCalc />;
    slug = "/quilt-backing-calculator";
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle} | Gibson Threads</title>
        <link rel="canonical" href={"https://gibsonthreads.com" + slug} />
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content="{pageTitle} | Gibson Threads" />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imgSource} />
      </Helmet>
      <NavBar displayCenter={false} title={pageTitle} />
      <div className="quiltCalcForm">
        {content}
        <Footer />
      </div>
    </div>
  );
}

function BindingCalc() {
  const [returnValue, setReturnValue] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("imperial");

  const [quiltHeight, setQuiltHeight] = useState(0);
  const [quiltWidth, setQuiltWidth] = useState(0);
  const [bindingWidth, setBindingWidth] = useState(0);
  const [widthOfFabric, setWidthOfFabric] = useState(42);

  let unitDenotion = unitMeasure === "imperial" ? "(in)" : "(cm)";

  function handleSettingChange(event, setting) {
    switch (setting) {
      case "wof":
        setWidthOfFabric(event.target.value);
        break;
      case "height":
        setQuiltHeight(event.target.value);
        break;
      case "width":
        setQuiltWidth(event.target.value);
        break;
      case "bindingWidth":
        setBindingWidth(event.target.value);
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "BindingQuiltCalc",
      action: "setting-change",
      label: { setting }, // optional
    });
  }

  function returnFabricCalc() {
    //handle errors of empty inputs
    if (quiltHeight * quiltWidth * bindingWidth * widthOfFabric === 0) {
      setReturnValue("failure");
      return;
    }

    console.log("Quilt Width: " + quiltWidth);
    console.log("Quilt Height: " + quiltHeight);
    console.log("Binding Width: " + bindingWidth);

    /* Binding length in (in/cm) is the perimeter of the quilt
        plus seam allowance for each column of fabric pieced together.
        Seam allowance is double the width of the binding*/
    let bindingLength = 2 * quiltHeight + 2 * quiltWidth;
    bindingLength =
      bindingLength + (bindingLength / widthOfFabric) * bindingWidth * 2;
    console.log("Binding Length: " + bindingLength);

    /* Sub units (in/cm) needed from binding length is the binding width 
        multiplied by the binding length. The helper function prints this into yards/meters */
    let subUnitsNeeded =
      Math.ceil(bindingLength / widthOfFabric) * bindingWidth;

    setReturnValue(
      unitMeasure === "imperial"
        ? printFabricEstHelperImperial(subUnitsNeeded)
        : printFabricEstHelperMetric(subUnitsNeeded),
    );
  }

  return (
    <div className="basicBackground">
      <div>
        <select
          className="overrideMarginAuto"
          onChange={(e) => setUnitMeasure(e.target.value)}
        >
          <option default value="imperial">
            Yards
          </option>
          <option value="metric">Meters</option>
        </select>
      </div>
      <label className="">
        Width Of Fabric {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={widthOfFabric}
          onChange={(e) => handleSettingChange(e, "wof")}
        ></input>
      </label>
      <label className="">
        Quilt Height {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltHeight}
          onChange={(e) => handleSettingChange(e, "height")}
        ></input>
      </label>
      <label className="">
        Quilt Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltWidth}
          onChange={(e) => handleSettingChange(e, "width")}
        ></input>
      </label>
      <label className="">
        Binding Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={bindingWidth}
          onChange={(e) => handleSettingChange(e, "bindingWidth")}
        ></input>
      </label>
      <button onClick={() => returnFabricCalc()}>Submit</button>
      {returnValue === "failure" && (
        <div className=" formError">
          Please make sure to fill out all inputs before submitting
        </div>
      )}
      {returnValue !== "failure" && returnValue !== "" && <p>{returnValue}</p>}
    </div>
  );
}

function BackingCalc() {
  const [returnValue, setReturnValue] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("imperial");

  const [quiltHeight, setQuiltHeight] = useState(0);
  const [quiltWidth, setQuiltWidth] = useState(0);
  const [margin, setMargin] = useState(0);
  const [widthOfFabric, setWidthOfFabric] = useState(42);

  let unitDenotion = unitMeasure === "imperial" ? "(in)" : "(cm)";

  function handleSettingChange(event, setting) {
    switch (setting) {
      case "wof":
        setWidthOfFabric(event.target.value);
        break;
      case "height":
        setQuiltHeight(event.target.value);
        break;
      case "width":
        setQuiltWidth(event.target.value);
        break;
      case "backingMargin":
        setMargin(event.target.value);
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "BackingQuiltCalc",
      action: "setting-change",
      label: { setting }, // optional
    });
  }

  function returnFabricCalc() {
    //handle errors of empty inputs
    if (quiltHeight * quiltWidth * widthOfFabric === 0) {
      setReturnValue("failure");
      return;
    }

    let minSideLength, maxSideLength;
    if (quiltHeight + margin < quiltWidth + margin) {
      minSideLength = parseFloat(quiltHeight) + parseFloat(margin);
      maxSideLength = parseFloat(quiltWidth) + parseFloat(margin);
    } else {
      minSideLength = parseFloat(quiltWidth) + parseFloat(margin);
      maxSideLength = parseFloat(quiltHeight) + parseFloat(margin);
    }

    //if minSide length is less than WOF, return maxSideLength in yards/meters
    if (minSideLength <= widthOfFabric) {
      setReturnValue(
        unitMeasure === "imperial"
          ? printFabricEstHelperImperial(maxSideLength)
          : printFabricEstHelperMetric(maxSideLength),
      );
    } else if (maxSideLength > widthOfFabric * 2) {
      //get the number of times the WOF (w/o seam allowance) fits in maxSide
      let repeats = Math.ceil(maxSideLength / (widthOfFabric - 0.5));
      //multiply by yards/meters for the short side, return
      setReturnValue(
        unitMeasure === "imperial"
          ? printFabricEstHelperImperial(minSideLength * repeats)
          : printFabricEstHelperMetric(minSideLength * repeats),
      );
    } else {
      //fabric needed is Math.Ceil(maxSideLength+ .5) including seam allowance
      setReturnValue(
        unitMeasure === "imperial"
          ? printFabricEstHelperImperial(Math.ceil(minSideLength + 0.5) * 2)
          : printFabricEstHelperMetric(Math.ceil(minSideLength + 0.5) * 2),
      );
    }
  }

  return (
    <div className="basicBackground">
      <div className="">
        <select
          className="overrideMarginAuto"
          onChange={(e) => setUnitMeasure(e.target.value)}
        >
          <option default value="imperial">
            Yards
          </option>
          <option value="metric">Meters</option>
        </select>
      </div>
      <label className="">
        Width Of Fabric {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={widthOfFabric}
          onChange={(e) => handleSettingChange(e, "wof")}
        ></input>
      </label>
      <label className="">
        Quilt Height {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltHeight}
          onChange={(e) => handleSettingChange(e, "height")}
        ></input>
      </label>
      <label className="">
        Quilt Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltWidth}
          onChange={(e) => handleSettingChange(e, "width")}
        ></input>
      </label>
      <label className="">
        Backing Margin {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={margin}
          onChange={(e) => handleSettingChange(e, "backingMargin")}
        ></input>
      </label>
      <div className="notation ">
        Backing margin is the width of extra fabric around your quilt top.
        Long-armers recommend 8-10 inches.
      </div>
      <button onClick={() => returnFabricCalc()}>Submit</button>
      {returnValue === "failure" && (
        <div className=" formError">
          Please make sure to fill out all inputs before submitting
        </div>
      )}
      {returnValue !== "failure" && returnValue !== "" && <p>{returnValue}</p>}
    </div>
  );
}
