import React from "react";
import "../../css/homeStyles.css";
import { Link } from "react-router-dom";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import calcCartoon from "../../Assets/Cartoon_Calc.svg";
import paletteExample from "../../Assets/colorPaletteCreatorExample.jpg";
import RecirculationUnit from "../RecirculationUnit/RecirculationUnit.js";
import { Helmet } from "react-helmet";

export function Home() {
  document.title = "Gibson Threads";

  return (
    <div data-testid="home">
      <Helmet>
        <title>
          Gibson Threads: Quilting Calculators and Other Digital Tools
        </title>
        <link rel="canonical" href={"https://gibsonthreads.com/"} />
        <meta property="og:title" content="Gibson Threads" />
        <meta
          property="og:description"
          content="Tools for the modern quilter: yardage calculators, color palette generators and more"
        />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_URL +
            "static/media/fabricSelectEx.4f370e3f180543d4c498.png"
          }
        />
      </Helmet>
      <div>
        <NavBar displayCenter={true} />
        <div className="homeGrid">
          <div className="homeGrid-full-line-item">
            <p className="description">
              Calculate the estimated fabric needed for your next sewing project
              with a piecing calculator as well as quilt binding and backing
              calculators.
            </p>
          </div>
          <div className="homeGrid-image-item">
            <img
              className="aboutItemMedia"
              src={calcCartoon}
              alt="Cartoon calculator"
            />
          </div>
          <div className="homeGrid-item">
            <Link
              to="/quiltcalc"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "quiltcalc", // optional
                })
              }
            >
              <h2>Quilt Piecing Yardage Calculator</h2>
            </Link>
            <p className="description">
              The piecing yardage calculator also offers a cut diagram to
              maximize your fabric usage.
            </p>
          </div>
          <div className="homeGrid-item">
            <Link
              to="/quilt-backing-calculator"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "quilt-backing-calculator", // optional
                })
              }
            >
              <h2>Quilt Backing Calculator</h2>
            </Link>
          </div>
          <div className="homeGrid-item">
            <Link
              to="/quilt-binding-calculator"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "quilt-binding-calculator", // optional
                })
              }
            >
              <h2>Quilt Binding Calculator</h2>
            </Link>
          </div>
        </div>
        <div className="homeGrid">
          <div className="homeGrid-full-line-item">
            <Link
              to="/color-palette-creator"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "color-palette-creator", // optional
                })
              }
            >
              <h2>Color Palette Creator</h2>
              <img
                className="aboutItemMedia"
                src={paletteExample}
                alt="Example of the color palette generator with a photo of an autumn evening"
                style={{ maxHeight: "500px", maxWidth: "500px" }}
              />
            </Link>
          </div>
          <div className="homeGrid-full-line-item">
            <p className="description">
              Create a color palette from your favorite photos to use in your
              next quilt project.
            </p>
          </div>
        </div>
        <div className="homeGrid">
          <div className="homeGrid-full-line-item">
            <Link
              to="/adeline-quilt-planning-tools"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "adeline-quilt-tools", // optional
                })
              }
            >
              <h2>Adeline Quilt Planner</h2>
              <img
                className="aboutItemMedia"
                src={
                  process.env.REACT_APP_URL +
                  "AdelineLetters/adelinequilt-racinisquilts.jpg"
                }
                alt="Adeline Quilt, designed by Racini's Quilts"
                style={{ maxHeight: "500px", maxWidth: "500px" }}
              />
            </Link>
          </div>
          <div className="homeGrid-full-line-item">
            <p className="description">
              Plan out the letters and PDF pages you need for the
              <a href="https://www.etsy.com/shop/RacinisQuilts">
                {" "}
                Adeline Quilt
              </a>
              .
            </p>
          </div>
        </div>
        <div className="homeGrid">
          <div className="homeGrid-item">
            <Link
              to="/about"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "about", // optional
                })
              }
            >
              <h2>About & How To&apos;s</h2>
            </Link>
          </div>
          <div className="homeGrid-item">
            <Link
              to="/blog"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "blog", // optional
                })
              }
            >
              <h2>Blog</h2>
            </Link>
          </div>
        </div>
        <RecirculationUnit />
        <div className="homeGrid">
          <div className="homeGrid-item">
            <Link
              to="https://docs.google.com/spreadsheets/d/1pcyrWNQ7hOTwLpJfX0vPmNlyRSc1tcta4FOLxpVy7VI/edit?usp=sharing"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "see-issues", // optional
                })
              }
            >
              <h2>See Known Issues and Planned Features</h2>
            </Link>
          </div>
          <div className="homeGrid-item">
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSdT0v-gDZjcLcW-xsYHWTqirAzNnEhFivQTittK2romToq9SA/viewform?usp=sf_link"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "home-grid-click",
                  label: "report-issues", // optional
                })
              }
            >
              <h2>Report Other Issues & Request Features </h2>
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
