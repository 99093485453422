import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { client, getImageSRC } from "../../sanity/sanityClientES.js";
import "../../css/homeStyles.css";
import "../../css/blogPostStyles.css";
import { cleanedDate } from "./BlogPostList/BlogPostList.js";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import RecirculationUnit from "../RecirculationUnit/RecirculationUnit.js";

import { getImageDimensions } from "@sanity/asset-utils";
import { PortableText } from "@portabletext/react";

const SampleImageComponent = ({ value }) => {
  const { width, height } = getImageDimensions(value);
  try {
    return (
      <img
        src={getImageSRC(value.asset._ref)}
        alt={value.alt}
        className="aboutItemMedia"
        loading="lazy"
        style={{
          // Avoid jumping around with aspect-ratio CSS property
          aspectRatio: width / height,
        }}
      />
    );
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
};

/* TODO: Determine if needed
const TagRecirc = (tags) => {
  console.log(JSON.stringify(tags));
  console.log(JSON.stringify(tags.tags));
  let returnedTags = tags.tags.map((t) => (
    <a
      key={t.id}
      href={process.env.REACT_APP_URL + "tag/" + t}
      onClick={() =>
        ReactGA.event({
          category: "Recirclation",
          action: "link-click",
          label: tags.tags, // optional
        })
      }
    >
      {t}
    </a>
  ));
  return <>{returnedTags}</>;
};
*/

export const BlogPost = () => {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    client
      .fetch(`*[slug.current == "${slug}"]`)
      .then((data) => setPostData(data[0]))
      .catch((error) => console.error(error));
  }, [slug]);

  if (!postData) return <div>Loading...</div>;
  return (
    <>
      <Helmet>
        <title>{postData.title} | Gibson Threads</title>
        <meta
          name="description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta property="og:title" content={postData.title} />
        <meta
          property="og:description"
          content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
        />
        <meta
          property="og:image"
          content={getImageSRC(postData.featuredImage.asset._ref)}
        />
        <link
          rel="canonical"
          href={"https://gibsonthreads.com/" + postData.slug.current}
        />
      </Helmet>
      <NavBar displayCenter={true} />
      <div>
        <div className="blogPostPage basicBackground">
          <h1>{postData.title}</h1>
          <img
            src={getImageSRC(postData.featuredImage.asset._ref)}
            alt={postData.featuredImage.alt}
            className="exampleFeaturedMedia"
          ></img>
          <div className="aboutItemText">
            <span className="author">By {postData.author}</span>
            <span className="pubDate">
              Published {cleanedDate(postData.date)}
            </span>
          </div>
          <div className="aboutItemText">
            <PortableText
              value={postData.content}
              components={{
                block: {
                  // Customize block types with ease
                  h1: ({ children }) => (
                    <h1 className="text-2xl">{children}</h1>
                  ),

                  // Same applies to custom styles
                  customHeading: ({ children }) => (
                    <h2 className="text-lg text-primary text-purple-700">
                      {children}
                    </h2>
                  ),
                },
                types: {
                  image: SampleImageComponent,
                },

                marks: {
                  link: ({ children, value }) => {
                    const rel = !value.href.startsWith("/")
                      ? "noreferrer noopener"
                      : undefined;
                    return (
                      <a
                        href={value.href}
                        rel={rel}
                        onClick={() =>
                          ReactGA.event({
                            category: "Recirclation",
                            action: "content-link-click",
                            label: value.href, // optional
                          })
                        }
                      >
                        {children}
                      </a>
                    );
                  },
                },
              }}
            />
          </div>
        </div>
        <RecirculationUnit />
        <Footer />
      </div>
    </>
  );
};

export default BlogPost;
