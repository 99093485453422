import React from "react";
import "./blockCompStyles.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

export const InputSettings = {
  Height: "height",
  Width: "width",
  CompType: "type",
  Repeat: "repeat",
  CornerWidth: "cornerWidth",
  CornerCount: "cornerCount",
  PosColors: "posColors",
};

export const CompTypes = {
  HST: "hst",
  Rect: "rect",
  Snowball: "snowball",
  FlyingGeese: "geese",
};

export const Grains = {
  Straight: "straightGrain",
  Cross: "crossGrain",
};

export function getCompFriendlyName(compType) {
  switch (compType) {
    case CompTypes.HST:
      return "Half Square Triangle";
    case CompTypes.Rect:
      return "Rectangle/Square";
    case CompTypes.Snowball:
      return "Snowball";
    case CompTypes.FlyingGeese:
      return "Flying Geese";
    default:
      return "";
  }
}

/*BlockComp Component Summary
This component builds a part of the quilt block to estimate
fabric need. A user selects the component type, the size and
the repeats of this component. They then select the fabric colors
they want for the elements of the component.
*/
export class BlockComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      compType: props.compType, //default compType flying geese
      height: props.height,
      width: props.width,
      cornerWidth: props.cornerWidth,
      cornerCount: props.cornerCount,
      repeat: props.repeat,
      posColors: props.posColors,
    };
  }

  /* Function Summary
  Handles user input to change type, width, height or repeat
   */
  handleCompSettingChange = (event, setting, position) => {
    console.log(setting + " set to: " + event.target.value);
    let parsedNum = Number.parseFloat(event.target.value);
    switch (setting) {
      case InputSettings.CompType:
        this.setState({ compType: event.target.value });
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.CompType,
          event.target.value,
        );
        return;
      case InputSettings.Height:
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.Height,
          parsedNum,
        );
        this.setState({ height: parsedNum });
        if (this.state.compType === CompTypes.HST) {
          this.props.handleUpdateToCompInput(
            this.props.id,
            InputSettings.Width,
            parsedNum,
          );
          this.setState({ width: parsedNum });
        }
        return;
      case InputSettings.Width:
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.Width,
          parsedNum,
        );
        this.setState({ width: parsedNum });
        return;
      case InputSettings.CornerWidth:
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.CornerWidth,
          parsedNum,
        );
        this.setState({ cornerWidth: parsedNum });
        return;
      case InputSettings.CornerCount:
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.CornerCount,
          parsedNum,
        );
        this.setState({ cornerCount: parsedNum });
        return;
      case InputSettings.Repeat:
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.Repeat,
          parseInt(event.target.value, 10),
        );
        this.setState({ repeat: parseInt(event.target.value, 10) });
        return;
      case InputSettings.PosColors: {
        //Do nothing if user selected default, empty value
        if (event.target.value === "") {
          return;
        }

        console.log(
          "Call to update Position: " +
            position +
            ", Color: " +
            event.target.value,
        );
        let updatedPosColors = this.state.posColors.map((p) => {
          if (p.pos === position) {
            let obj = p;
            obj.pos = position;
            obj.fabricId = event.target.value;
            console.log(
              "Completed update Position: " +
                position +
                " , FabricId " +
                obj.fabricId,
            );
            return obj;
          } else {
            // The rest haven't changed
            return p;
          }
        });
        this.setState({ posColors: updatedPosColors });
        this.props.handleUpdateToCompInput(
          this.props.id,
          InputSettings.PosColors,
          updatedPosColors,
        );
        return;
      }
      default:
        return;
    }
  };

  getFabricHexCode = (position) => {
    const fabricId = this.state.posColors.find(
      (p) => p.pos === position,
    ).fabricId;
    if (fabricId === "" || fabricId == null) {
      return "#ffffff";
    } else {
      return this.props.returnFormFabricColor(fabricId);
    }
  };

  getSnowballExample = () => {
    if (this.state.cornerCount === 4) {
      return (
        <svg height="100" width="100">
          <polygon
            points="0,0 100,0 100,100 0,100"
            style={{
              fill: this.getFabricHexCode("fill"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="75,0 100,25 100,0"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="0,0 25,0 0,25"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="0,100 25,100 0,75"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="100,100 75,100 100,75"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
        </svg>
      );
    } else if (this.state.cornerCount === 2) {
      return (
        <svg height="100" width="100">
          <polygon
            points="0,0 100,0 100,100 0,100"
            style={{
              fill: this.getFabricHexCode("fill"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="75,0 100,25 100,0"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="0,0 25,0 0,25"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
        </svg>
      );
    } else if (this.state.cornerCount === 3) {
      return (
        <svg height="100" width="100">
          <polygon
            points="0,0 100,0 100,100 0,100"
            style={{
              fill: this.getFabricHexCode("fill"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="75,0 100,25 100,0"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="0,0 25,0 0,25"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="0,100 25,100 0,75"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
        </svg>
      );
    } else {
      return (
        <svg height="100" width="100">
          <polygon
            points="0,0 100,0 100,100 0,100"
            style={{
              fill: this.getFabricHexCode("fill"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
          <polygon
            points="75,0 100,25 100,0"
            style={{
              fill: this.getFabricHexCode("corner"),
              stroke: "#000000",
              strokeWidth: "1px",
            }}
          />
        </svg>
      );
    }
  };

  /* Function Summary
  Displays the component example to the user and fills in
  the colors of the elements based on user input to color
  settings
   */
  getCompExample = () => {
    switch (this.state.compType) {
      case CompTypes.Rect:
        return (
          <svg height="100" width="200">
            <polygon
              points="0,0 200,0 200,100 0,100"
              style={{
                fill: this.getFabricHexCode("fill"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
          </svg>
        );
      case CompTypes.HST:
        return (
          <svg height="100" width="100">
            <polygon
              points="0,0 0,100 100,100"
              style={{
                fill: this.getFabricHexCode("bottomTri"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
            <polygon
              points="0,0 100,0 100,100"
              style={{
                fill: this.getFabricHexCode("topTri"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
          </svg>
        );
      case CompTypes.FlyingGeese:
        return (
          <svg height="100" width="200">
            <polygon
              points="0,0 0,100 100,0"
              style={{
                fill: this.getFabricHexCode("left"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
            <polygon
              points="100,0 200,100 0,100"
              style={{
                fill: this.getFabricHexCode("middle"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
            <polygon
              points="100,0 200,100 200,0"
              style={{
                fill: this.getFabricHexCode("right"),
                stroke: "#000000",
                strokeWidth: "1px",
              }}
            />
          </svg>
        );
      case CompTypes.Snowball:
        return this.getSnowballExample();
      default:
        return;
    }
  };

  /* Function Summary
  Update's the state of this component with updated fabric 
  list from the BlockForm
   */
  getFabricList = () => {
    this.setState({ fabricList: this.props.returnFormFabricList() });
  };

  /* Function Summary
  Handles user input to delete this component
   */
  handleCompDelete = () => {
    console.log("Clicked to delete " + this.props.id);
    //call parent function to delete this comp
    this.props.deleteBlockComp(this.props.id);
  };

  getErrorMessage = () => {
    let draftErrorMessage = [];
    if (
      this.state.compType === CompTypes.Snowball &&
      (this.state.cornerCount <= 0 || this.state.cornerCount > 4)
    ) {
      draftErrorMessage = draftErrorMessage.concat(
        <p>Snowballs must have 1-4 corners blocks set</p>,
      );
    }

    let matchingPosColors = this.state.posColors.filter((p) =>
      p.matchingCompTypes.includes(this.state.compType),
    );
    matchingPosColors.forEach((p) => {
      if (p.fabricId === "" || p.fabricId == undefined) {
        draftErrorMessage = draftErrorMessage.concat(
          <p>
            {p.friendlyPosName.toLowerCase()} Missing position fabric selection
          </p>,
        );
      }
    });

    if (this.state.height === 0 || this.state.height == undefined) {
      draftErrorMessage = draftErrorMessage.concat(<p>Missing height</p>);
    }
    if (this.state.width === 0 || this.state.width == undefined) {
      draftErrorMessage = draftErrorMessage.concat(<p>Missing width</p>);
    }
    if (this.state.repeat === 0 || this.state.repeat == undefined) {
      draftErrorMessage = draftErrorMessage.concat(<p>Missing block repeat</p>);
    }
    if (
      this.state.compType === CompTypes.Snowball &&
      (this.state.cornerWidth == undefined || this.state.cornerWidth === 0)
    ) {
      draftErrorMessage = draftErrorMessage.concat(
        <p>Missing snowball corner width/height</p>,
      );
    }
    return draftErrorMessage.map((e) => {
      return e;
    });
  };

  render() {
    return (
      <div className="blockComp">
        <div className="blockComp-first-row">
          <h2 className="blockCompTitle">
            <select
              aria-label="Block Component Type"
              data-testid="blockCompType"
              className="blockCompInput"
              onChange={(e) =>
                this.handleCompSettingChange(e, InputSettings.CompType)
              }
            >
              <option value={CompTypes.FlyingGeese}>
                {getCompFriendlyName(CompTypes.FlyingGeese)}
              </option>
              <option value={CompTypes.Rect}>
                {getCompFriendlyName(CompTypes.Rect)}
              </option>
              <option value={CompTypes.HST}>
                {getCompFriendlyName(CompTypes.HST)}
              </option>
              <option value={CompTypes.Snowball}>
                {getCompFriendlyName(CompTypes.Snowball)}
              </option>
            </select>
          </h2>
          <Tooltip className="blockCompDelete" title="Delete">
            <IconButton>
              <DeleteIcon onClick={this.handleCompDelete} />
            </IconButton>
          </Tooltip>
        </div>
        <div className="blockCompEx">{this.getCompExample()}</div>
        <div className="blockComp-second-row">
          <div className="blockCompSettings">
            <label>
              {this.state.compType === CompTypes.HST
                ? "Height/Width (in):"
                : "Height (in):"}
              <input
                data-testid="blockCompHeight"
                className="blockCompInput"
                type="number"
                step="0.01"
                value={this.state.height}
                onChange={(e) =>
                  this.handleCompSettingChange(e, InputSettings.Height)
                }
              />
            </label>
          </div>
          <div
            className="blockCompSettings"
            style={{
              display: this.state.compType === CompTypes.HST ? "none" : "block",
            }}
          >
            <label>
              Width (in):
              <input
                data-testid="blockCompWidth"
                type="number"
                step="0.01"
                className="blockCompInput"
                value={this.state.width}
                onChange={(e) =>
                  this.handleCompSettingChange(e, InputSettings.Width)
                }
              />
            </label>
          </div>
          <div
            className="blockCompSettings"
            style={{
              display:
                this.state.compType === CompTypes.Snowball ? "block" : "none",
            }}
          >
            <label>
              Corner Height/Width (in):
              <input
                data-testid="blockCompSnowHeightWidth"
                type="number"
                step="0.01"
                className="blockCompInput snowballInput"
                value={this.state.cornerWidth}
                onChange={(e) =>
                  this.handleCompSettingChange(e, InputSettings.CornerWidth)
                }
              />
            </label>
          </div>
          <div
            className="blockCompSettings"
            style={{
              display:
                this.state.compType === CompTypes.Snowball ? "block" : "none",
            }}
          >
            <label>
              Corner Count:
              <input
                type="number"
                step="1"
                min="0"
                max="4"
                className="blockCompInput snowballInput"
                value={this.state.cornerCount}
                onChange={(e) =>
                  this.handleCompSettingChange(e, InputSettings.CornerCount)
                }
              />
            </label>
          </div>
          <div className="blockCompSettings">
            <Tooltip title="Number of times component repeats in the block">
              <label>
                Repeat:
                <input
                  data-testid="blockCompRepeat"
                  type="number"
                  step="1"
                  className="blockCompInput"
                  value={this.state.repeat}
                  onChange={(e) =>
                    this.handleCompSettingChange(e, InputSettings.Repeat)
                  }
                />
              </label>
            </Tooltip>
          </div>
        </div>
        <div className="blockComp-third-row">
          {this.state.posColors
            .filter((p) => p.matchingCompTypes.includes(this.state.compType))
            .map((p) => {
              return (
                <div key={p.pos} className="blockCompCustomSettings">
                  <label>
                    {p.friendlyPosName} Color:
                    <select
                      data-testid={"blockCompPosColor-" + p.pos}
                      onFocus={this.getFabricList}
                      onTouchStart={this.getFabricList}
                      className="blockCompInput"
                      onChange={(e) =>
                        this.handleCompSettingChange(
                          e,
                          InputSettings.PosColors,
                          p.pos,
                        )
                      }
                    >
                      <option default value="#ffffff"></option>
                      {this.state.fabricList}
                    </select>
                  </label>
                </div>
              );
            })}
        </div>
        <div
          className="blockCompError"
          style={{
            display: this.props.displayError ? "block" : "none",
          }}
        >
          {this.props.displayError && this.getErrorMessage()}
        </div>
      </div>
    );
  }
}
