import React, { useState, useEffect } from "react";
import "../../css/adelineHelper.css";
import "../../css/homeStyles.css";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import RecirculationUnit from "../RecirculationUnit/RecirculationUnit.js";
import { debounce } from "lodash";
import letterData from "../../Assets/AdelineHelper/adelineLetterData.json";
import pageData from "../../Assets/AdelineHelper/adelinePDFPageCategories.json";

/*Page to offer helper functions related to a complex quilt pattern
Functions:
Preview of phrase
Phrase width calculation
PDF page filtering */
export function AdelineHelper() {
  const [SVGLetters, setSVGLetters] = useState([]);
  const [phrase, setPhrase] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [phraseWidth, setPhraseWidth] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [pageNumberDisplay, setPageNumDisplay] = useState("");

  const [excludeRect, setExcludeRect] = useState(true);
  const [excludeTri, setExcludeTri] = useState(false);
  const [excludeExtras, setExcludeExtras] = useState(true);
  const placeholderText = "Enter the name or words for your quilt...";

  /* On `excludeRect`, `excludeTri`, or `excludeExtras` change,
    update svgDisplay and get new PDF page numbers*/
  useEffect(() => {
    getSVGDisplay(phrase);
  }, [excludeRect, excludeTri, excludeExtras]); // Dependencies array

  /*Display the page numbers needed for the user inputs selected
    Page numbers include reference to the templates, instructions and other helpful tools of the PDF*/
  const getPageNumberDisplay = (pNums) => {
    //indicate loading
    setPageNumDisplay("Loading...");

    //dedupe pagenumbers from params or state
    let tempPageNumbers;
    pNums == undefined
      ? (tempPageNumbers = [...new Set(pageNumbers)])
      : (tempPageNumbers = [...new Set(pNums)]);

    //order
    tempPageNumbers = tempPageNumbers.map(Number).sort((a, b) => a - b);

    //handle fencepost display of commas
    setPageNumDisplay(
      tempPageNumbers.slice(0, -1).join(", ") +
        (tempPageNumbers.length > 1 ? `, ${tempPageNumbers.slice(-1)[0]}` : ""),
    );
  };

  /*
    Based on user input to phrase, get page numbers needed and phrase width */
  const getSVGDisplay = debounce((userInput) => {
    //error handling special characters, trailing spaces
    let regex = /^[A-Za-z\s]+$/; // match only letters and spaces
    if (!regex.test(userInput)) {
      if (userInput === "") {
        return;
      }
      setErrorMessage(
        "Phrase enters includes special characters. Please only include letters and spaces.",
      );
      return;
    }
    //remove error message for properly formatted input
    setErrorMessage("");

    userInput.trim(); //remove trailing whitespace

    //get width of each letter, SVGs to display and page numbers
    let estPhraseWidth = 0;
    let svgArray = [];
    let tempPageNumbers = [];
    for (let i = 0; i < userInput.length; i++) {
      let char = userInput[i].toUpperCase();
      if (letterData[char]) {
        //get the width of the lettersetErrorMessage
        estPhraseWidth = estPhraseWidth + letterData[char].finishedWidth;
        //get whitespace width between each letter, except last letter and any spaces
        //splitting userInput at each space, creating array of substrings
        //The number of spaces is then one less than the length of this array
        if (i < userInput.length - (1 + userInput.split(" ").length - 1)) {
          estPhraseWidth += 0.75;
        }
        svgArray.push(letterData[char].imgSRC);
        svgArray.push("p");

        //add the page numbers for letters as needed with user settings
        tempPageNumbers.push(...letterData[char].templatePages);
      } else if (char === " ") {
        estPhraseWidth += 1;
        svgArray.push("p");
        svgArray.push("p");
      }
    }

    //set values to reference on front end
    setPhraseWidth(estPhraseWidth);
    setPhrase(userInput);
    if (phraseWidth > 54) {
      setErrorMessage(
        "Warning: Phrase width is larger than crib quilt size (54 inches).",
      );
    }
    if (phraseWidth > 76) {
      setErrorMessage(
        "Warning: Phrase width is larger than large throw quilt size (76 inches).",
      );
    }
    setSVGLetters(svgArray);

    //get page numbers
    for (const pageNumber in pageData) {
      if (Object.prototype.hasOwnProperty.call(pageData, pageNumber)) {
        const pageDetails = pageData[pageNumber];
        /*if it's:
                 -  a curve template
                 OR
                 {
                     - a tri AND excludeTri = false AND not a letter template (already added from above)
                     - a rect AND excludeRect = false AND not a letter template (already added from above)
                     - a extras/other AND excludeExtras = false
                 }
                */
        if (pageDetails.LetterTemplate === true) {
          continue;
        }
        if (
          (pageDetails.Category === "Other" ||
            pageDetails.Category === "Helpful Tool" ||
            pageDetails.Category === "Piecing Instructions") &&
          excludeExtras === false
        ) {
          tempPageNumbers.push(pageDetails.Page);
          continue;
        }
        if (pageDetails.Template.includes("Curve") === true) {
          tempPageNumbers.push(pageDetails.Page);
          continue;
        }
        if (pageDetails.Template.includes("Triangle") && excludeTri === false) {
          tempPageNumbers.push(pageDetails.Page);
          continue;
        }
        if (pageDetails.Template === "Rectangle" && excludeRect === false) {
          tempPageNumbers.push(pageDetails.Page);
          continue;
        }
        if (pageDetails.Category === "Other" && excludeExtras === false) {
          tempPageNumbers.push(pageDetails.Page);
          continue;
        }
      }
    }
    setPageNumbers(tempPageNumbers);
    getPageNumberDisplay(tempPageNumbers);
  }, 300);

  return (
    <div>
      <Helmet>
        <title>Adeline Quilt Helper | Gibson Threads | Racini Quilts</title>
        <link
          rel="canonical"
          href={"https://gibsonthreads.com/adeline-quilt-planning-tools"}
        />
        <meta
          name="description"
          content="Adeline quilt planning tool for Racini Quilt's Pattern."
        />
        <meta
          property="og:title"
          content="Adeline Quilt Helper | Gibson Threads | Racini Quilts"
        />
        <meta
          property="og:description"
          content="Adeline quilt planning tool for previewing phrase, estimating width and filtering PDF pages for Racini Quilt's pattern."
        />
        <meta
          property="og:image"
          content={
            process.env.REACT_APP_URL +
            "AdelineLetters/adelinequilt-raciniquilts.jpg"
          }
        />
      </Helmet>
      <NavBar displayCenter={false} title="Adeline Quilt Helper" />
      <div className="adelineHelperForm basicBackground">
        <div className="narrowColumn">
          <h2>Preview Display</h2>
          <div className="letterPreviewDisplay">
            {SVGLetters.map((element, index) => (
              <React.Fragment key={index}>
                {element === "p" ? (
                  <div style={{ width: "10px", display: "inline-block" }} />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_URL + `AdelineLetters/${element}`
                    }
                    alt={`Letter ${element.replace(".svg", "")}`}
                    style={{
                      width: "auto",
                      height: "100px",
                      display: "inline-block",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          <input
            size={placeholderText.length}
            placeholder={placeholderText}
            onChange={(e) => getSVGDisplay(e.target.value)}
          />
          {phraseWidth > 0 ? (
            <div>
              <span>Width of finished letters: </span>
              {phraseWidth}
              <span> inches</span>
              <p>
                <b>Note: </b>Spaces between words will be estimated with a
                1&quot; finished size. Spaces between letters will be estimated
                with a 3/4&quot; finished size.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="softFormError">{errorMessage}</div>
        </div>
        <div className="narrowColumn">
          <h2>PDF Page Finder</h2>
          <p>
            Streamline your document printing by identifying the specific page
            numbers you need, eliminating the need to print the entire file.
          </p>
          <label>
            <input
              type="checkbox"
              alt="Exclude all rectangular templates from the PDF pages to print."
              checked={excludeRect}
              onChange={(e) => {
                setExcludeRect(e.target.checked);
              }}
            />
            Exclude Rectangular Templates
          </label>
          <label>
            <input
              type="checkbox"
              alt="Exclude all triangular templates from the PDF pages to print."
              checked={excludeTri}
              onChange={(e) => {
                setExcludeTri(e.target.checked);
              }}
            />
            Exclude Triangular Templates
          </label>
          <label>
            <input
              type="checkbox"
              alt="Exclude all non-templates pages from the PDF pages to print."
              checked={excludeExtras}
              onChange={(e) => {
                setExcludeExtras(e.target.checked);
              }}
            />
            Exclude All Non-Template Pages (Written Instructions)
          </label>
          {phraseWidth > 0 ? (
            <div>
              <span>Pages Needed: </span>
              {pageNumberDisplay}
              <div className="softFormError">
                Letter templates not excluded with filters above
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="narrowColumn">
          <h2>
            More Info on the Adeline Quilt by{" "}
            <a
              href="https://www.instagram.com/racinisquilts/"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "outbound",
                  label: "https://www.instagram.com/racinisquilts/", // optional
                })
              }
            >
              Racini Quilts
            </a>
          </h2>
          <img
            src={
              process.env.REACT_APP_URL +
              "AdelineLetters/adelinequilt-racinisquilts.jpg"
            }
            className=""
            alt="Digital rendering of Adeline Quilt by Racini's Quilts"
            href="https://www.instagram.com/racinisquilts/"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "outbound",
                label: "https://www.instagram.com/racinisquilts/", // optional
              })
            }
          />
          <p>
            The Adeline Quilt is a fully customizable quilt designed to mimic
            the playfulness of children&apos;s building blocks. Racini named the
            quilt after her daughter, Adeline. The pattern comes with designs
            for all 26 alphabet letters and several fun additions.
          </p>
          <p>
            <a
              href="https://www.etsy.com/shop/RacinisQuilts"
              onClick={() =>
                ReactGA.event({
                  category: "Recirculation",
                  action: "outbound",
                  label: "https://www.etsy.com/shop/RacinisQuilts", // optional
                })
              }
            >
              Get the Adeline Quilt here
            </a>
          </p>
        </div>
      </div>
      <RecirculationUnit />
      <Footer />
    </div>
  );
}
